
export default {
	data(){
		return {
			index: 1,
			isVisible: false,
			activeItem: Object,
		}
	},
	/**
	 *  the modal window owns the array, index and activeItem
	 * 	the modal window children bind to the activeItem
	 */
	props:{
		collection: Array
	},
	methods: {
		show: function(index){
			if (index != undefined) {
				this.index = index;
				this.activeItem = this.collection[this.index];
			}
			this.isVisible = true;
		//	setTimeout(() => { this.isVisible = true; }, 50)
		},
		prev()
		{
			this.index = this.index == 0 ? this.collection.length - 1 : this.index - 1;
			this.activeItem = this.collection[this.index];
		},
		next()
		{
			this.index = this.index == this.collection.length - 1 ? 0 : this.index + 1;
			this.activeItem = this.collection[this.index];
		}
	}
}