<template lang='pug'>
li(@click="onItemSelected")
	img.photo(:data-src="memory.media.img_url" :alt="memory.media.description")
	img.video-icon(v-if="memory.media.type=='video'" src='/img/gfx/video.svg' alt='video')
</template>

<script>

export default{
	props:{
		memory:Object
	},
	methods:{
		// onImgLoaded(e)
		// {
		// 	e.target.parentElement.classList.add('fadeIn');
		// },
		onItemSelected(e){
			let li = e.target.parentElement;
			let ul = li.parentElement;
			let selectedIndex = Array.prototype.indexOf.call(ul.children, li);
			this.$emit('show-modal', selectedIndex);
		}
	}
}

</script>

<style lang="stylus" scoped>
li
	opacity 0
	height 20vh
	min-width 20vw // required for lazy loading to work, container must have a width & height
	flex-grow 1
	margin: inherit
	position relative
	.photo
		min-width 100%
		max-height 100%
		object-fit cover
		border-radius 5px
		vertical-align bottom
	.video-icon
		background white
		padding 6px
		height 36px
		top 0; right 0
		position absolute
	$scale_speed = .2s
	$opacity_speed = 1s
	transition transform $scale_speed linear, opacity $opacity_speed
	-o-transition -o-transform $scale_speed linear, opacity $opacity_speed
	-moz-transition -moz-transform $scale_speed linear, opacity $opacity_speed
	-webkit-transition -webkit-transform $scale_speed linear, opacity $opacity_speed
li.fadeIn
	opacity 1 !important


li:hover
	transform scale(.98)

</style>