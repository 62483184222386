<template>
	<Header></Header>
	<router-view id='content' v-slot="{ Component }">
		<transition name="fade" mode='out-in'>
			<component :is="Component" />
		</transition>
	</router-view>
	<Footer></Footer>
</template>

<script>
	import Header from './components/global/Header.vue';
	import Footer from './components/global/Footer.vue';

export default {
	components : {
		Header, Footer
	}
}

</script>

<style lang="stylus">

@import './assets/css/libs/core.styl';

$header_height = 40px
html, body, #app
	margin 0
	padding 0
	height 100%
#content
	flex 1 0 auto
	margin-top $header_height
	margin-bottom 40px
#app
	display flex
	flex-direction column

.fade-enter-from, .fade-leave-to
	opacity 0

.fade-enter-active, .fade-leave-active
	transition opacity .25s ease-out

</style>
