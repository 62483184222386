
export default {
	methods:{
		attachIntersectionObserver(selector){
			if ('IntersectionObserver' in window) {
				let lazyImages = [].slice.call(document.querySelectorAll(selector));
				let lazyImageObserver = new IntersectionObserver((entries, observer) => {
					entries.forEach((entry) => {
						if (entry.isIntersecting) {
							let target = entry.target;
							let lazyImage = target;
							if (lazyImage.tagName != 'IMG') lazyImage = target.querySelector('img');
							lazyImage.onload = () => {
								lazyImage.onload = undefined;
								target.classList.add('fadeIn');
							// refresh masonry if we're using it //
								if (this.msnry) this.msnry.layout();
							}
							lazyImage.src = lazyImage.dataset.src;
							lazyImageObserver.unobserve(lazyImage);
						}
					});
				});
				lazyImages.forEach(function(lazyImage) {
					lazyImageObserver.observe(lazyImage);
				});
			}	else{
			// fallback to manually loading everything one at a time //
				let index = 0;
				let images = document.querySelectorAll(selector);
				let total = images.length;
				var loadNextImage = function()
				{
					var img = images[index];
					img.onload = function(){
						img.onload = undefined;
						img.classList.add('fadeIn');
						if (++index < total) loadNextImage();
					}
					img.style.display = 'none';
					img.src = img.dataset.src;
				}
				loadNextImage();
			}
		}
	}
}