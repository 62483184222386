<template lang='pug'>
div
	.page
		.header
			h1.sm.w60 William Xavier Braitsch Jr.
			h4 A message from Will's family
			p You may have known him as Will or Mr. Braitsch, to us he was simply Billy. As devastated as we are at the sudden loss of our brother and son, we are immensely proud of all that he accomplished in his short time on this earth. The profound impact he had on his thousands of students, colleagues and friends throughout the world fills our hearts with joy and we thank you all for accepting Will into your life. We hope this site can be a place for us to remember Will,&nbsp;
				router-link(to='/contribute') share memories and laughs,&nbsp;
				| introduce newcomers to his unique style of teaching and provide an easy way for us to&nbsp;
				router-link(to='/contribute') stay in touch.&nbsp;
				| Thank you for keeping the memory of our son and brother close to your heart and thank you for taking the time to help us remember Will.
		video(src='/vid/jchs-memorial.mp4' poster='/vid/jchs-memorial.jpg' controls)
		ul
			HomeCard(:memory='memory' :key='memory._id' v-for='memory in memories' @show-modal="onItemSelected")
		MediaViewer(v-if='memories' ref='modal' :collection='memories')
</template>

<script>

import HomeCard from "./HomeViewCard"
import MediaViewer from "./MediaViewer"
import LazyLoader from '../../components/mixins/LazyLoader'

export default {
	data(){
		return {
			memories:[]
		}
	},
	mixins:[LazyLoader],
	components:{
		HomeCard,
		MediaViewer
	},
	methods:{
		onItemSelected(selectedIndex){
			this.$refs.modal.show(selectedIndex);
		}
	},
	created() {
		(async () => {
			const res = await fetch('/api/medias')
			.then(response => response.json())
			.then(json => {
				console.log(json);
				this.memories = json;
			// add slight delay to allow cards to render //
				setTimeout(this.attachIntersectionObserver, 250, 'li');
			// add slight delay before showing deeplinked media //
				setTimeout(() => {
					let memoryId = window.location.pathname.replace('/memory/', '');
					this.memories.some((m, i) => {
						if (m._id === memoryId) { this.onItemSelected(i); return true; }
					});
				}, 1000);
			})
		})();
	}
}

</script>

<style lang="stylus" scoped>

// https://css-tricks.com/adaptive-photo-layout-with-flexbox/

h4
	margin 3vh auto
	font-size .9rem
	font-weight 500
	font-family 'Roboto', sans-serif;
	text-transform uppercase

video
	margin auto
	display block
	max-width 98vw
	max-height 46vh
	border-radius 5px

ul
	$margin = .5vmin
	display flex
	flex-wrap wrap
	margin $margin
	&:after
		content ''
		display block
		flex-grow 10

//@media (max-width: 800px)
	// need to experiment w/ sizing on small screens
	// li
	// 	height 14vh
	// li
	// 	width 100vw
// Portrait

// @media (max-aspect-ratio: 1/1)
// 	li
// 		height 30vh

// // Short screens

// @media (max-height: 480px)
// 	li
// 		height 80vh

// // Smaller screens in portrait

// @media (max-aspect-ratio: 1/1) and (max-width: 480px)
// 	ul
// 		flex-direction row
// 	li
// 		height auto
// 		width 100%
// 	img
// 		width 100%
// 		max-height 75vh
// 		min-width 0

</style>