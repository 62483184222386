<template>
	<header :class='{scrolled : (isScrolled && (mNavIsVisible == false))}'>
		<img class='mNavBtn' @click='showMobileNav' src='/img/gfx/mobile_nav.svg'>
		<nav v-if='showAdminNav' ref='nav' :class='{ mNavVisible:mNavVisible }' >
			<router-link class='navItem' to="/">View Site</router-link>
			<router-link class='navItem' to="/admin/medias">Medias</router-link>
			<router-link class='navItem' to="/admin/stories">Stories</router-link>
			<router-link class='navItem' to="/admin/queue">Queue</router-link>
			<a class='navItem' href='/admin/tmol'>TMOL</a>
			<a class='navItem' href='/admin/messages'>Messages</a>
			<a class='navItem' href='/admin/scholarships'>Scholarships</a>
			<div class='navItem' @click='onBackupDatabase'>Backup</div>
			<div class='navItem' @click='onRestoreDatabase'>Restore</div>
			<div class='navItem right' @click='onLogoutClicked'>Logout</div>
		</nav>
		<nav v-else ref='nav' :class='{ mNavVisible:mNavVisible }' >
			<router-link class='navItem' to="/">Home</router-link>
			<router-link class='navItem' to="/in-memoriam">In Memoriam</router-link>
			<router-link class='navItem' to="/library">Library</router-link>
			<router-link class='navItem' to="/teaching">Teaching</router-link>
			<router-link class='navItem' to="/tmol">The Meaning of Life</router-link>
			<router-link class='navItem' to="/about">About William</router-link>
			<router-link class='navItem' to="/contribute">Contribute</router-link>
			<router-link class='navItem' to="/scholarships">Scholarships</router-link>
			<router-link v-if='isLoggedIn' class='navItem' to="/admin/queue">Dashboard</router-link>
			<!-- <router-link v-if='isLoggedIn==false' class='navItem' to="/login">Login</router-link> -->
			<div v-if='isLoggedIn' class='navItem right' @click='onLogoutClicked'>Logout</div>
		</nav>
	</header>
</template>

<script>

export default{
	data() {
		return {
			isScrolled: false,
			isLoggedIn: false,
			showAdminNav: false,
			mNavVisible: false,
			mNavIsVisible: false
		}
	},
	watch: {
		'$route': function(route) {
			this.showAdminNav = route.path.search('admin') != -1;
		}
	},
	methods:{
		showMobileNav()
		{
			this.mNavVisible = this.mNavIsVisible = true;
		},
		hideMobileNav(){
			this.mNavVisible = false;
		// wait for transition to complete //
			setTimeout(() => { this.mNavIsVisible = false; }, 500);
		},
		async onBackupDatabase()
		{
			await fetch('/admin/backup', {
				method: 'POST',
				credentials: 'include',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				}
			})
			.then(response => { this.$router.push('/admin/medias'); });
		},
		async onRestoreDatabase()
		{
			if (confirm('Are you sure you want to restore the database from the last backup?')){
				await fetch('/admin/restore', {
					method: 'POST',
					credentials: 'include',
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json'
					}
				})
				.then(response => { this.$router.push('/admin/medias'); });
			}
		},
		onLogoutClicked(){
			fetch('/logout', {credentials: 'include'})
			.then(response => {
				window.sessionStorage.clear();
				this.$store.commit('setUserData', null);
				this.$router.push('/');
			});
		},
		onWindowClicked(e){
			let ignore = ['mNavBtn', 'mNavVisible'];
			let hideNav = true;
			ignore.forEach(c => {
				if (e.target.classList.contains(c)) hideNav = false;
			});
			if (hideNav) this.hideMobileNav();
		},
		onWindowResized(e){
			if (window.innerWidth > 800 && this.mNavIsVisible == true){
				this.mNavIsVisible = false;
			}	else if (window.innerWidth < 800 && this.mNavIsVisible == false){
				this.mNavIsVisible = true;
			}
		},
		onWindowScrolled (e) {
			this.isScrolled = window.scrollY > 40;
		}
	},

	computed:{
		isLoggedIn:function(){
			return this.$store.state.isLoggedIn;
		},
	},
	created () {
		window.addEventListener('click', this.onWindowClicked);
		window.addEventListener('resize', this.onWindowResized);
		window.addEventListener('scroll', this.onWindowScrolled);
	},
	destroyed () {
		window.removeEventListener('click', this.onWindowClicked);
		window.removeEventListener('resize', this.onWindowResized);
		window.removeEventListener('scroll', this.onWindowScrolled);
	}
}

</script>

<style lang='stylus' scoped>
header
	z-index 9999
	width 100vw
	top 0; left 0
	position fixed
	font-size .9rem
	font-weight 500
	.mNavBtn
		top 8px
		right 14px
		height 40px
		padding 8px
		display none
		position absolute
		cursor pointer
		background white
	nav
		width 100vw
		height 40px
		display flex
		position absolute
		align-items center
		justify-content center
		transition right .5s ease
		border-bottom 1px solid #ced4da
	.navItem
		color #333
		margin 0 24px
		cursor pointer
		line-height 1.3rem
		text-decoration none
		flex-shrink 0
		transition color $speed linear
	.navItem:hover
		color #777
	.navItem.right
		margin-left auto
header.scrolled
	nav
		background-color #222
	.navItem
		color #fff
	.navItem:hover
		color #ccc

@media (max-width: 800px)
	header
		.mNavBtn
			display block
		nav
			width 160px
			right -160px
			height 100vh
			background white
			padding 20px 10px
			border-right 1px solid #eee
			flex-direction column
			align-items flex-start // align left
			justify-content flex-start // align to top
			box-shadow: -12px 3px 53px -5px rgba(0,0,0,0.1);
			-webkit-box-shadow: -12px 3px 53px -5px rgba(0,0,0,0.1);
			-moz-box-shadow: -12px 3px 53px -5px rgba(0,0,0,0.1);
			.navItem, .navItem:last-child
				margin 14px 20px
		nav.mNavVisible
			right 0

@media (min-width: 800px)
	header
		nav, .navItem
			transition: background-color .3s linear

</style>