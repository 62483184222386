import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'

export type USER = {
	fname: string,
	lname: string,
	email: string,
	isAdmin: boolean,
	authenticated: boolean,
}
// define state
export type State = {
	counter: number
	user?: USER,
	isLoggedIn: boolean
};

// default state
const state: State = {
	counter: 0,
	isLoggedIn: false
};

export const store = createStore({
    plugins: [createPersistedState({
        storage: window.sessionStorage,
    })],
	state,
	mutations: {
		increment(state, payload) {
			console.log('incrementing counter');
			state.counter++;
		},
		setUserData(state, udata){
			state.user = udata;
			state.isLoggedIn = state.user != null;
		//	console.log('setting userdata', state.user);
		}
	},
	actions: {
		increment({ commit }) {
			commit("increment");
		}
	},
	getters: {
	// method name must be the same as the property //
		counter(state) {
			return state.counter;
		},
		isAdmin: state => {
			return state.user && state.user.isAdmin;
		},
		isLoggedIn: state => {
			return state.isLoggedIn;
		}
		// user(state){
		// 	return state.user;
		// }
	},
	modules: {}
});
