<template lang='pug'>
div(v-if='activeItem')
	.modal.md-effect-9(:class="{'modal-show': isVisible}")
		.modal-content
			Viewport(:dataObj='activeItem' selectable=false showNavBtns=true showAdminBtns=false @prev='prev', @next='next', @close='isVisible=false')
			.info
				.text
					.authorAndMessage(v-if='activeItem.text')
						span.author {{authorName}} &middot;
						span {{activeItem.text}}
					.authorAndMessage(v-else)
						.author Shared by {{authorName}}
					.dateAndLocation {{dateAndLocation}}
				img.link(src='/img/gfx/link.svg' :data-clipboard-text='computeLink' @click='copyLink')
			//img.close(src='/img/gfx/close.svg' @click="isVisible=false")
	.modal-overlay(@click="isVisible=false")
</template>

<script>

import moment from "moment"
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/animations/shift-away.css';
import ClipboardJS from 'clipboard'
import Modal from '../../components/mixins/Modal'
import Viewport from "../../components/Viewport"

export default{
	name: 'MediaViewer',
	mixins: [Modal],
	components:{ Viewport },
	data(){
		return {
			toolTip: tippy,
			authorName: String,
			dateAndLocation: String
		}
	},
	watch:{
		isVisible:function(){
			if (this.isVisible == false) {
				let v = document.querySelector('.media video'); if (v) v.pause();
				this.toolTip.hide();
			}
		},
		activeItem:function(e){
			let m = this.activeItem.media.meta;
			let d = m.date_user || m.date_auto;
			if (d) d = moment(d).format('MMMM Do YYYY');
			this.dateAndLocation = d;
			let l = m.location_user;
			if (l == undefined && m.location_auto && m.location_auto.address){
			// fall back on auto detected location if exists //
				l = m.location_auto.address.city + ', ' + m.location_auto.address.state;
			}
			if (l) this.dateAndLocation += ' – ' + l;
			this.authorName = this.activeItem.fname;// +' '+ this.activeItem.lname.charAt(0)+'.';
		}
	},
	computed:{
		computeLink(){
			return 'https://' + window.location.hostname + '/memory/'+this.activeItem._id;
		}
	},
	methods:{
		copyLink(){
			this.toolTip.setContent('Copied!');
			this.toolTip.show();
			setTimeout(this.toolTip.hide, 1500);
		}
	},
	mounted(){
		let clipboard = new ClipboardJS('.link', {
			target: function(trigger) { return trigger.text; }
		});
		// clipboard.on('success', function(e) {
		// 	console.info('Action:', e.action);
		// 	console.info('Text:', e.text);
		// 	console.info('Trigger:', e.trigger);
		// 	e.clearSelection();
		// });
		clipboard.on('error', function(e) {
			console.error('Action:', e.action);
			console.error('Trigger:', e.trigger);
		});
		this.toolTip = tippy(document.querySelector('.link'), {
			arrow: true,
			theme: 'default',
			animation: 'shift-away',
			placement: 'left',
			hideOnClick: 'false',
			content: 'Copy Link',
			touch: ['hold', 500],
			onHidden: () => { this.toolTip.setContent('Copy Link'); }
		});
	}
}

</script>

<style lang="stylus" scoped>

.info
	display flex
	align-items flex-start
	margin-top .7rem
	padding 0 4px
	.text
		text-align left
		line-height 1.2rem
		.authorAndMessage
			color #333
			padding-right 6px
			font-size .9rem
			margin-bottom .3rem
			.author
				color #333
				font-weight 600
				margin-right 5px
		.dateAndLocation
			color #777
			font-size .7rem
			text-transform uppercase
	.link
		width 26px
		cursor pointer
		margin-left auto

// .close
// 	top 0; right 0;
// 	position absolute
// 	width 4vmin
// 	min-width 28px
// 	padding 9px
// 	cursor pointer
// 	background white

@media screen and (orientation: portrait)
	.dateAndLocation
		font-size .6rem !important

</style>