<template lang="pug">
.media-wrap(:class='{isDragOver:isDragOver}' v-cloak @drop.prevent="onFileDropped" @dragover.prevent='isDragOver=true' @dragleave.prevent='isDragOver=false')
	.media(:class='{selectable:selectable}' @click='onSelectFile')
		img(v-if="dataObj.media == undefined" src='img/photos/beach.jpg' style='object-fit:fill')
		img(v-else-if="dataObj.media.type=='photo'" :src="dataObj.media.img_url" :alt="dataObj.text")
		video(v-else-if="dataObj.media.type=='video'" :src="dataObj.media.vid_url" :poster="dataObj.media.img_url" :alt="dataObj.text" controls)
	input(ref='input_file' @change='onFileSelected' type='file' accept='image/jpeg,image/x-png, video/*' style='display:none')
	.progress(:class="{ fadeIn: isUploading }")
		.black
		.line
			.b
			.f(:style="{ width: uploadProgress + '%' }")
		.text {{ uploadProgress }}%
	.nav(v-if='showNavBtns')
		img.p(src="/img/gfx/chevron_left.svg" @click='prev' alt='previous photo')
		img.n(src="/img/gfx/chevron_right.svg" @click='next' alt='next photo')
		img.u(v-if="showAdminBtns" src="/img/gfx/chevron_up.svg" @click='onSelectFile' alt='select file')
		img.d(v-if="showAdminBtns && dataObj.media" src="/img/gfx/chevron_down.svg" @click='viewSource' alt='view source')
</template>

<script>
	export default{
		data(){
			return{
				file: null,
				isDragOver: false,
				isUploading: false,
				uploadProgress: 0,
			}
		},
		props:{
			dataObj: Object,
			autoUpload: Boolean,
			selectable: Boolean,
			showNavBtns: Boolean,
			showAdminBtns: Boolean
		},
		methods:{
			prev()
			{
				if (document.activeElement.tagName == 'BODY') this.$emit('prev');
			},
			next()
			{
				if (document.activeElement.tagName == 'BODY') this.$emit('next');
			},
			stopVideo()
			{
				document.querySelector('video').pause();
			},
			onSelectFile()
			{
				if (this.selectable) this.$refs.input_file.click();
			},
			onFileDragOver()
			{
				console.log('drag over');
			},
			onFileDragLeave()
			{
				console.log('drag leave');
			},
			onFileDropped(e)
			{
				if (e.dataTransfer.files && e.dataTransfer.files[0]){
					this.inspectFile(e.dataTransfer.files[0]);
				}
			},
			onFileSelected(e){
				if (e.target.files && e.target.files[0]) {
					this.inspectFile(e.target.files[0]);
				}
			},
			inspectFile(file)
			{
				const MAX_IMAGE_FILE_SIZE = 10 // megabytes //
				const MAX_VIDEO_FILE_SIZE = 500 // megabytes //
				const size = (file.size / 1000000).toFixed(2);
				if (file.type.search('image') != -1){
					if (size > MAX_IMAGE_FILE_SIZE){
						alert('The maximum photo size is '+MAX_IMAGE_FILE_SIZE+'MB. Your photo is '+size+'MB. Please select a smaller file.');
					}	else{
						this.file = file;
						var reader = new FileReader();
						reader.onload = (e) => {
							this.dataObj.media = {
								type : 'photo',
								img_url: e.target.result
							};
							if (this.autoUpload) this.uploadMedia();
						}
						reader.readAsDataURL(this.file);
						this.$emit('file-selected');
					}
				}	else if (file.type.search('video') != -1){
					if (size > MAX_VIDEO_FILE_SIZE){
						alert('The maximum video size is '+MAX_VIDEO_FILE_SIZE+'MB. Your video is '+size+'MB. Please select a smaller file.');
					}	else{
						this.file = file;
						this.dataObj.media = {
							type: 'video',
							vid_url: URL.createObjectURL(this.file)
						};
						if (this.autoUpload) this.uploadMedia();
						this.$emit('file-selected');
					}
				}	else{
					alert('Please select a photo or video file.')
				}
			},
			viewSource()
			{
				if (this.dataObj.media.type == 'video'){
					window.open(this.dataObj.media.vid_url);
				}	else if (this.dataObj.media.type == 'photo'){
					window.open(this.dataObj.media.img_url.replace('.jpg', '_src.jpg'));
				}
			},
			uploadMedia()
			{
				var request = new XMLHttpRequest();
				request.upload.onloadstart = () => { this.isUploading = true; }
				request.upload.onprogress = (e) => {
					var percentComplete = Math.round(e.loaded / e.total * 100);
					this.uploadProgress = percentComplete;
				};
				request.onreadystatechange = () => {
					if (request.readyState == 4) {
						this.isUploading = false;
						if (request.status == 200){
						// 	delay so progress loader can fade out //
							let json = JSON.parse(request.responseText);
							setTimeout(() => { this.$emit('upload-complete', json); }, 500);
						}	else{
							alert('Yikes! It looks like something went wrong, please try again in a few moments.');
						}
					}
				};
				request.open('POST', '/upload/media');
				var formData = new FormData();
				formData.append('file', this.file);
				request.send(formData);
			},
			reset(){
			//	reset the file input & media preview //
				try{
					let input = this.$refs.input_file;
					input.value = null;
					input.type = 'file';
					if (this.dataObj.media) URL.revokeObjectURL(this.dataObj.media.vid_url);
				}
				catch(e){
					console.log('unable to reset the media uploader:', e);
				}
			},
			onArrowKeys(e)
			{
				if (e.key == 'ArrowLeft'){
					this.prev();
				}	else if (e.key == 'ArrowRight'){
					this.next();
				}	else if (e.key == 'Escape'){
					this.$emit('close');
				}
			}
		},
		mounted() {
			if (this.showNavBtns) window.addEventListener('keyup', this.onArrowKeys);
		},
		unmounted(){
			if (this.showNavBtns) window.removeEventListener('keyup', this.onArrowKeys);
		}
	}

</script>

<style lang="stylus" scoped>
.media-wrap
	position relative
	background #eee
	transition padding .2s
	.media
		img, video
			max-width 90vw
			max-height 80vh
			margin auto
			display block
			object-fit contain
		background white
	// attempt to fit better on mobile landscape //
	@media screen and (orientation: landscape) and (max-height:400px)
		.media
			img, video
				max-height 50vh
	.media.selectable
		cursor pointer
	.nav > img
		width 28px
		height 34px
		position absolute
		top 50%;
		cursor pointer
		padding 8px 6px
		background white
		transform translateY(-50%)
	.nav > img.p
		left -3vmin
	.nav > img.n
		right -3vmin
	.nav > img.u, .nav > img.d
		top 100%
		padding 6px
		transform translateY(-100%)
	.nav > img.u
		left -17px
	.nav > img.d
		right -17px
	.progress
		top 0; left 0;
		opacity 0
		width 100%
		height 100%
		position absolute
		pointer-events none
		.black
			top 0
			width 100%
			height 100%
			position absolute
			background rgba(0,0,0,.5)
		.line
			.b
				width 100%
				background-color #333
			.f
				width 50%
				background-color #65C8F0
				transition width .15s ease
			.b, .f
				height 4px
				top calc( 50% - 2px)
				position absolute
		.text
			top 54%
			width 100%
			text-align center
			margin auto
			color white
			position absolute
	.progress.fadeIn
		opacity 1

.media-wrap.isDragOver
	padding 4px
</style>