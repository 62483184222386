import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/home/HomeView.vue'
import x404 from '../views/utils/x404.vue'

const routes: Array<RouteRecordRaw> = [
	{
		path: '/',
		component: Home
	},
	{
		path: '/memory/:id',
		component: Home
	},
	{
		path: '/about',
		component: () => import(/* webpackChunkName: "about" */ '../views/about/AboutView.vue')
	},
	{
		path: '/in-memoriam',
		component: () => import(/* webpackChunkName: "in-memoriam" */ '../views/in-memoriam/MemoriesView.vue')
	},
	{
		path: '/in-memoriam/:id',
		component: () => import(/* webpackChunkName: "in-memoriam.memory" */ '../views/in-memoriam/MemoryView.vue'),
	},
	{
		path: '/library',
		component: () => import(/* webpackChunkName: "library" */ '../views/library/LibraryView.vue')
	},
	{
		path: '/book/:id',
		component: () => import(/* webpackChunkName: "library.book" */ '../views/library/BookView.vue'),
	},
	{
		path: '/teaching',
		component: () => import(/* webpackChunkName: "teaching" */ '../views/teaching/TeachingView.vue')
	},
	{
		path: '/tmol',
		component: () => import(/* webpackChunkName: "tmol" */ '../views/tmol/Intro.vue'),
	},
	{
		path: '/tmol/write',
		component: () => import(/* webpackChunkName: "tmol.write" */ '../views/tmol/Write.vue'),
	},
	{
		path: '/tmol/submit',
		component: () => import(/* webpackChunkName: "tmol.submit" */ '../views/tmol/Submit.vue'),
	},
	{
		path: '/contribute',
		component: () => import(/* webpackChunkName: "contribute" */ '../views/share/ShareMemory.vue')
	},
	{
		path: '/scholarships',
		component: () => import(/* webpackChunkName: "scholarships" */ '../views/scholarships/Scholarships.vue')
	},
	{
		path: '/login',
		component: () => import(/* webpackChunkName: "login" */ '../views/account/Login.vue')
	},
	{
		path: '/create-account',
		component: () => import(/* webpackChunkName: "create-account" */ '../views/account/Create.vue')
	},
	{
		path: '/unsubscribe/:status',
		component: () => import(/* webpackChunkName: "unsubscribe" */ '../views/utils/Unsubscribe.vue'),
	},
	{
		path: '/admin/:page',
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/admin/Dashboard.vue'),
		meta: { requiresAuth: true }
	},
	{ path: '/:pathMatch(.*)*', component: x404 }
]
// route level code-splitting
// this generates a separate chunk (about.[hash].js) for this route
// which is lazy-loaded when the route is visited.
// component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL), routes
});

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
		fetch('/authenticated', {credentials: 'include'})
		.then(response => response.json())
		.then(json => {
			if (json.authenticated){
				next();
			}	else{
				next({path: '/login', params: {nextUrl: to.fullPath}});
			}
		}).catch((e) => {
			console.log(e);
		});
    }	else {
        next();
    }
})

export default router
