<template lang='pug'>
footer
	p &copy; {{getFullYear()}}&nbsp;
		a(href='https://braitsch.io') Made by Braitsch
</template>

<script>
	export default{
		methods:{
			getFullYear(){
				return new Date().getFullYear()
			}
		}
	}
</script>

<style lang="stylus" scoped>
footer
	color white
	padding 20px
	display flex
	justify-content center
	font-size 1rem
	background-color #222
	a
		color white
</style>